import Icon from '@/components/Icon'
import Checkbox from '@/components/nsw/Checkbox'
import Pagination from '@/components/nsw/Pagination'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { CommonCopyUrlWrapper } from '@/components/ui/copy-to-clipboard/CommonCopyUrlWrapper'
import VideoModal, {
	useVideoModal,
} from '@/legacy-ported/components/base/VideoModal'
import { UrlLink } from '@/legacy-ported/utilities/frontendTypes'
import { stringCompare } from '@/legacy-ported/utilities/functions'
import { DownloadListField } from '@/types/downloadList'
import {
	DownloadListAzureDataProps,
	DownloadListAzureField,
} from '@/types/downloadListAzureData'
import { FetchResourcesResponseData } from '@/types/fetchResourcesFromAzure'
import { getTagFromYears } from '@/utils'
import { getFilesizeFormatter } from '@/utils/getFilesizeFormatter'
import {
	isResourceContainer,
	isTeachingAdvice,
	isWebLinkVideo,
	isWebLinkext,
	isWebLinkint,
} from '@/utils/type_predicates'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx'
import {
	DataGrid,
	GridCallbackDetails,
	GridColDef,
	GridColumnVisibilityModel,
	GridRenderCellParams,
	GridRowParams,
	GridSelectionModel,
	GridSortDirection,
	GridSortModel,
	gridPageCountSelector,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid'
import clsx from 'clsx'
import format from 'date-fns/format'
import JsFileDownloader from 'js-file-downloader'
import { Button } from 'nsw-ds-react'
import {
	Fragment,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from '../legacy-ported/sass/components/downloadlist/DownloadList.module.scss'

const fileSize = getFilesizeFormatter()

const renderAsSmall = (params) => <small>{params.value}</small>

export const handleResourceDownload = (e: MouseEvent<HTMLAnchorElement>) => {
	e.preventDefault()
	new JsFileDownloader({
		url: e.currentTarget.href,
		autoStart: true,
		forceDesktopMode: true,
	})
}

const stylesGrid: SxProps<Theme> = {
	'&': {
		border: 0,
	},
	'* + p': {
		marginTop: 0,
	},
	'.MuiDataGrid-columnHeaders': {
		borderBottom: '2px solid var(--nsw-grey-01)',
	},
	'.MuiDataGrid-cell': {
		whiteSpace: 'normal !important',
		wordWrap: 'break-word !important',
		'&:focus': {
			outline: 'none',
		},
	},
	'.MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold',
	},
	'.MuiDataGrid-columnSeparator': {
		display: 'none',
	},
	'.Mui-disabled': {
		'&.MuiCheckbox-root': {
			pointerEvents: 'none',
			cursor: 'not-allowed',

			'svg rect': {
				fill: '#F2F2F2',
				stroke: '#CDD3D6',
				// '&:nth-child(2)': {
				// 	stroke: '#F2F2F2',
				// },
			},
			'svg path': {
				display: 'none',
			},
		},
	},
	'.MuiDataGrid-iconButtonContainer.mui-style-ltf0zy-MuiDataGrid-iconButtonContainer':
		{
			width: '20px !important',
		},

	'.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:focus-within':
		{
			outline: 'none !important',
		},
	'.MuiDataGrid-row': {
		borderBottom: '1px solid var(--nsw-grey-01)',
	},
	'.MuiDataGrid-selectedRowCount': {
		display: 'none',
	},
	'.MuiDataGrid-footerContainer': {
		justifyContent: 'center',
		marginTop: '2rem',
		borderTop: '0',
	},
	'.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox:focus, .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox:focus-within, .MuiDataGrid-columnHeader[data-field=download]:focus, .MuiDataGrid-columnHeader[data-field=info]:focus':
		{
			outline: 'none',
		},
	'.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
		outline: 'solid 3px var(--nsw-focus)',
		outlineOffset: '-4px',
	},
}

const getUrlLinkFromFileAzureDataObj = (
	file: FetchResourcesResponseData,
): UrlLink => {
	const title = file.title
	const url = file.url

	return {
		title,
		url,
	}
}

export const getFileTypeFromFileAzureDataObj = (
	file: FetchResourcesResponseData,
) => {
	return 'TODO FILETYPE'
}

const DEFAULT_SORTING_ORDER = ['asc', 'desc']

export const DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST: DownloadListAzureField[] = [
	'syllabuses',
	'officialreleasedate',
	'info',
]

const INITIAL_SORT_MODEL: DownloadListAzureDataProps['initialSortModel'] = [
	{
		field: 'title',
		sort: 'asc',
	},
]

const sortComparatorByFilename = (
	a: FetchResourcesResponseData,
	b: FetchResourcesResponseData,
) => {
	return stringCompare(a.title, b.title)
}

export const getSizeFromFileAzureData = (item: FetchResourcesResponseData) => {
	return item.filesize || ''
}

export const getResourceTypeFromFileAzureData = (
	item: FetchResourcesResponseData,
) => {
	if (item) {
		return item.resource_type.name || ''
	}

	return ''
}

export const getStageFromFileAzureData = (item: FetchResourcesResponseData) => {
	if (item) {
		const stages = item.stages
		return stages?.length > 1 ? 'Multiple' : stages?.[0]?.name || ''
	}

	return ''
}

export const getSyllabusFromFileAzureData = (
	item: FetchResourcesResponseData,
): string => {
	const syllabusesLength = item.syllabuses.length
	if (!syllabusesLength) return ''
	if (syllabusesLength === 1) {
		return item.syllabuses[0].name
	}
	return 'Multiple'
}

/**
 * Get the updated date from the file object
 * @param item the file object
 * @param field the field to get the date from
 * @returns
 */
export const getUpdatedDateFromFileAzureData = (
	item: FetchResourcesResponseData,
): string => {
	return item.officialreleasedate
		? format(new Date(item.officialreleasedate), 'MMM yyyy')
		: '-'
}

function CustomPagination() {
	const apiRef = useGridApiContext()
	const page = useGridSelector(apiRef, gridPageSelector)
	const pageCount = useGridSelector(apiRef, gridPageCountSelector)

	// if pageCount is 1, don't show pagination
	if (pageCount === 1) return null

	return (
		<Pagination
			count={pageCount}
			page={page + 1}
			onChange={(_ev, value) => apiRef.current.setPage(value - 1)}
		/>
	)
}

/**
 * A list of files to download
 * @param props
 * @constructor
 */
export const DownloadListAzureData = (
	props: DownloadListAzureDataProps,
): JSX.Element => {
	const {
		page,
		pageSize = 100,
		className,
		files,
		onSelectedFiles,
		hideCheckbox = false,
		hiddenFields = DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST,
		showSelectAllCheckbox = false,
		isIncludeCopyUrlOnTitle = false,
		onPageChange,
		columnSettings,
		showNextToTitleTooltip = false,
		showFileSizeUnderTitle = false,
		initialSortModel = INITIAL_SORT_MODEL,
		onSortModelChange,
		...dataGridProps
	} = props

	const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel)

	const isFilesIncludeWebLinkExternalOrInternal = files.some(
		(file) => isWebLinkext(file) || isWebLinkint(file),
	)

	// video modal
	const {
		currentVideoIframeUrl,
		openVideoModal,
		currentVideoLabel,
		openVideo,
		hideVideo,
	} = useVideoModal()

	const rows = files.map((item) => {
		return {
			id: item.system.id,
			title: item,
			syllabuses: getSyllabusFromFileAzureData(item),
			fileType: getFileTypeFromFileAzureDataObj(item),
			fileSize: getSizeFromFileAzureData(item),
			stages: getStageFromFileAzureData(item),
			resource_type: getResourceTypeFromFileAzureData(item),
			year: getTagFromYears(item.years),
			officialreleasedate: getUpdatedDateFromFileAzureData(item),
			info: item,
			download: item,
		}
	})
	// .sort((a, b) => sortComparatorByFilename(a.title, b.title))

	const getRowHeight = useCallback(() => 'auto', [])
	const filesLessThenPageSize =
		(dataGridProps.rowCount ?? files.length) <= pageSize

	const handleVideoClick = useCallback(
		(ev: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
			ev.preventDefault()
			const { videourl, videolabel } = ev.currentTarget.dataset
			openVideo(videourl, videolabel)
		},
		[openVideo],
	)

	const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
		if (onSelectedFiles) {
			onSelectedFiles(
				selectionModel.map((id) => {
					const _file = files.find((file) => file.system.id === id)
					return {
						id: id as string,
						size: _file?.filesize,
					}
				}),
			)
		}
	}

	const handleSortModelChange = (
		newSortModel: GridSortModel,
		details: GridCallbackDetails<any>,
	) => {
		setSortModel(newSortModel)
		if (onSortModelChange) {
			onSortModelChange(newSortModel, details)
		}
	}

	const columns = useMemo(
		() =>
			[
				{
					field: 'title',
					headerName: 'Item',
					flex: 1,
					sortComparator: sortComparatorByFilename,
					renderCell: (
						params: GridRenderCellParams<FetchResourcesResponseData>,
					) => {
						const _file: FetchResourcesResponseData = params.value
						const { title, url } = getUrlLinkFromFileAzureDataObj(
							params.value,
						)
						const isFileExternalLink = isWebLinkext(_file)
						const isAdvice = isTeachingAdvice(_file)
						const isFileVideo = isWebLinkVideo(_file)
						const isFileResourceContainer =
							isResourceContainer(_file)

						let onClick = undefined
						if (isFileVideo) onClick = handleVideoClick
						if (isFileResourceContainer)
							onClick = handleResourceDownload
						const _fileSize = getSizeFromFileAzureData(_file)
						const fileSizeStr = _fileSize ? fileSize(_fileSize) : ''

						let excludeOrigin: boolean | undefined =
							isIncludeCopyUrlOnTitle || undefined
						if (
							(isAdvice || isFileResourceContainer) &&
							isIncludeCopyUrlOnTitle
						) {
							excludeOrigin = false
						}
						const CompWrapper = isIncludeCopyUrlOnTitle
							? CommonCopyUrlWrapper
							: Fragment

						const CompWrapperProps: any = isIncludeCopyUrlOnTitle
							? {
									url,
									excludeOrigin,
							  }
							: {}

						return (
							<div className="flex items-center justify-between gap-2 w-full py-2.5">
								<span>
									<CompWrapper {...CompWrapperProps}>
										<span>
											{/* eslint-disable-next-line react/jsx-no-target-blank */}
											<a
												href={url}
												className="no-underline download-list__link no-icon"
												download={
													isFileResourceContainer
														? true
														: undefined
												}
												onClick={onClick}
												target={
													isFileExternalLink
														? '_blank'
														: undefined
												}
												rel={`noopener noreferrer ${
													isFileResourceContainer
														? 'noindex nofollow'
														: ''
												}`.trim()}
												data-videourl={
													isFileVideo
														? url
														: undefined
												}
												data-videolabel={
													isFileVideo
														? title
														: undefined
												}
											>
												{title}
											</a>
										</span>
									</CompWrapper>

									{showFileSizeUnderTitle && fileSizeStr && (
										<div>
											<small>{fileSizeStr}</small>
										</div>
									)}
								</span>
							</div>
						)
					},
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef<any, FetchResourcesResponseData>,

				{
					field: 'fileType',
					headerName: 'File type',
					width: isFilesIncludeWebLinkExternalOrInternal ? 150 : 120,
					align: 'left',
					headerAlign: 'left',
					renderCell: renderAsSmall,
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'fileSize',
					headerName: 'File size',
					width: 120,
					align: 'left',
					headerAlign: 'left',
					renderCell: (params) => {
						if (!params?.value) return '-'
						return <small>{fileSize(params.value)}</small>
					},
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'officialreleasedate',
					headerName: 'Updated',
					width: 120,
					align: 'left',
					headerAlign: 'left',
					renderCell: (params) => {
						if (!params?.value) return '-'
						return <small>{params.value}</small>
					},
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'resource_type',
					headerName: 'Resource Type',
					width: 176,
					align: 'left',
					headerAlign: 'left',
					sortable: true,
					renderCell: renderAsSmall,
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'stages',
					headerName: 'Stage',
					width: 106,
					align: 'left',
					headerAlign: 'left',
					sortable: true,
					renderCell: renderAsSmall,
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'year',
					headerName: 'Year',
					width: 106,
					align: 'left',
					headerAlign: 'left',
					sortable: false,
					renderCell: renderAsSmall,
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'syllabuses',
					headerName: 'Syllabus',
					width: 185,
					align: 'left',
					headerAlign: 'left',
					renderCell: renderAsSmall,
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
				{
					field: 'info',
					headerName: '',
					width: 24,
					sortable: false,
					renderCell: (
						params: GridRenderCellParams<FetchResourcesResponseData>,
					) => {
						const _file: FetchResourcesResponseData = params.value
						const syllabus = getSyllabusFromFileAzureData(_file)
						const resourceType =
							getResourceTypeFromFileAzureData(_file)
						const stage = getStageFromFileAzureData(_file)
						const dateStr = getUpdatedDateFromFileAzureData(_file)

						return (
							showNextToTitleTooltip && (
								<Tooltip
									iconSizeInTooltip={20}
									iconSizeInButton={24}
									text={
										<div className="font-normal grid gap-1">
											{dateStr && (
												<div>
													<strong>Updated:</strong>{' '}
													{dateStr}
												</div>
											)}
											{resourceType && (
												<div>
													<strong>
														Resource type:
													</strong>{' '}
													{getResourceTypeFromFileAzureData(
														_file,
													)}
												</div>
											)}
											{stage && (
												<div>
													<strong>Stage:</strong>{' '}
													{getStageFromFileAzureData(
														_file,
													)}
												</div>
											)}
											{syllabus && (
												<div>
													<strong>Syllabus:</strong>{' '}
													{syllabus}
												</div>
											)}
										</div>
									}
									disableTouchListener
								/>
							)
						)
					},
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef<any, FetchResourcesResponseData>,
				{
					field: 'download',
					headerName: '',
					width: 56,
					align: 'left',
					headerAlign: 'left',
					sortable: false,
					renderCell: (params) => {
						const _file: FetchResourcesResponseData = params.value
						const { url, title } =
							getUrlLinkFromFileAzureDataObj(_file)
						const isFileAsset = isResourceContainer(_file)
						const isFileExternalLink = isWebLinkext(_file)
						const isFileInternalLink = isWebLinkint(_file)
						const isAdvice =
							isTeachingAdvice(_file) ||
							_file.system.type === 'teaching_advice'

						if (
							isFileAsset ||
							isFileExternalLink ||
							isFileInternalLink
						) {
							return (
								<Button
									linkComponent="a"
									link={url}
									onClick={
										isFileAsset
											? handleResourceDownload
											: undefined
									}
									target={
										isFileExternalLink
											? '_blank'
											: undefined
									}
									className="!min-w-0 w-9 h-9 p-0 flex items-center justify-center no-icon"
									aria-label={
										isFileAsset ? 'Download' : 'Open link'
									}
									rel={`noopener noreferrer ${
										isFileAsset ? 'noindex nofollow' : ''
									}`.trim()}
								>
									{isFileAsset ? (
										<Icon
											icon={'mdi:download'}
											width={20}
											height={20}
										/>
									) : (
										<Icon
											icon={'mdi:external-link'}
											width={20}
											height={20}
										></Icon>
									)}
								</Button>
							)
						}
						// TA
						if (isAdvice) {
							return (
								<Button
									linkComponent="a"
									link={url}
									className="!min-w-0 w-9 h-9 p-0 flex items-center justify-center no-icon"
									aria-label="Open link"
								>
									<Icon
										icon={'mdi:arrow-right'}
										width={20}
										height={20}
									/>
								</Button>
							)
						}
						// Video
						return (
							<Button
								onClick={handleVideoClick}
								className="!min-w-0 w-9 h-9 p-0 flex items-center justify-center no-icon"
								aria-label="Show Video"
								data-videourl={url}
								data-videolabel={title}
							>
								<Icon
									icon={'mdi:arrow-right'}
									width={20}
									height={20}
								/>
							</Button>
						)
					},
					hideable: false,
					disableColumnMenu: true,
				} as GridColDef,
			].map((column) => {
				if (columnSettings?.[column.field as DownloadListField]) {
					return {
						...column,
						...columnSettings[column.field as DownloadListField],
					}
				}
				return column
			}),
		[
			columnSettings,
			handleVideoClick,
			isFilesIncludeWebLinkExternalOrInternal,
			isIncludeCopyUrlOnTitle,
			showFileSizeUnderTitle,
			showNextToTitleTooltip,
		],
	)

	const columnVisibilityModel = useMemo(() => {
		const _columnVisibilityModel: GridColumnVisibilityModel =
			columns.reduce((acc, column) => {
				return {
					...acc,
					[column.field]: !hiddenFields.includes(
						column.field as DownloadListAzureField,
					),
				}
			}, {})
		return _columnVisibilityModel

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hiddenFields.join(',')])

	const [pageNumber, setPage] = useState(page)
	useEffect(() => {
		if (pageNumber !== undefined) {
			setPage(page)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	return (
		<>
			<div
				className={clsx(
					'w-full h-auto overflow-auto DownloadList',
					styles.DownloadList,
					className,
				)}
			>
				<DataGrid
					{...dataGridProps}
					className="leading-normal"
					page={pageNumber}
					pageSize={pageSize}
					rowsPerPageOptions={[pageSize]}
					sortModel={sortModel}
					columns={columns}
					rows={rows}
					checkboxSelection={!hideCheckbox}
					autoHeight
					disableColumnFilter
					components={{
						BaseCheckbox: Checkbox,
						NoRowsOverlay: () => {
							return (
								<Stack
									height="100%"
									alignItems="center"
									justifyContent="center"
								>
									No results found.
								</Stack>
							)
						},
						Pagination: CustomPagination,
					}}
					getRowHeight={getRowHeight}
					hideFooter={
						dataGridProps.hideFooter ?? filesLessThenPageSize
					}
					hideFooterPagination={
						dataGridProps.hideFooterPagination ??
						filesLessThenPageSize
					}
					sx={[
						stylesGrid,
						{
							'.MuiDataGrid-columnHeaderCheckbox > *': {
								display: showSelectAllCheckbox
									? undefined
									: 'none',
							},
						},
					]}
					onSelectionModelChange={handleSelectionModelChange}
					onSortModelChange={handleSortModelChange}
					disableSelectionOnClick
					isRowSelectable={(
						params: GridRowParams<{
							title: FetchResourcesResponseData
						}>,
					) => {
						return isResourceContainer(params.row.title)
					}}
					onPageChange={onPageChange}
					columnVisibilityModel={columnVisibilityModel}
					sortingOrder={DEFAULT_SORTING_ORDER as GridSortDirection[]}
					pagination
				></DataGrid>
			</div>
			{openVideoModal && currentVideoIframeUrl && (
				<VideoModal
					ariaLabel={currentVideoLabel}
					modalStatus={openVideoModal}
					onCancel={hideVideo}
					video={currentVideoIframeUrl}
				/>
			)}
		</>
	)
}

export default DownloadListAzureData
