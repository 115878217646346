import { FetchGlossariesParams } from '@/types/fetchGlossariesFromAzure'
import {
	commonAzureDurableRequest,
	getApiDomain,
} from './curriculum-azure-functions'

export type GlossariesDownloadParams = Omit<
	FetchGlossariesParams,
	'page' | 'page_size' | 'codenames'
> & {
	isPreviewMode?: boolean
}

export async function getGlossaries(
	params: GlossariesDownloadParams,
	abortSignal?: AbortSignal,
) {
	return await commonAzureDurableRequest<GlossariesDownloadParams>(
		getApiDomain(params.isPreviewMode) + '/api/PrintGlossaryHttpTrigger',
		params,
		abortSignal,
	)
}

export default getGlossaries
