import { useKontentHomeConfig } from '@/components/contexts/KontentHomeConfigProvider'
import {
	TaxoResourceType,
	TaxoStage,
	TaxoSyllabus,
	TaxoSyllabustype,
} from '@/kontent/taxonomies'
import { TaxoStageWithLifeSkill, TaxoStageYearWithLifeSkill } from '@/types'
import { downloadCustomResource } from '@/utils/downloadCustomResource'
import { filterAssetSelectedStages } from '@/utils/filterAssetSelectedStages'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export interface ISimpleFile {
	id: string
	size: number
	resourceType?: TaxoResourceType
	stages?: TaxoStageWithLifeSkill[]
	years?: TaxoStageYearWithLifeSkill[]
	syllabuses?: TaxoSyllabus[]
	syllabus_types?: TaxoSyllabustype[]
}

export const useDownloadSelectedFiles = ({
	maxFileSizeInMB = 25,
	files = [],
	alertEl,
	downloadCustomResourceSyllabusName = '',
	stages = [],
	syllabuses = [],
	resourceTypes = [],
	years = [],
	onSuccessfulDownload,
}: {
	maxFileSizeInMB?: number
	files?: ISimpleFile[]
	alertEl?: HTMLElement
	downloadCustomResourceSyllabusName?: string
	stages?: TaxoStageWithLifeSkill[]
	syllabuses?: TaxoSyllabus[]
	resourceTypes?: TaxoResourceType[]
	years?: TaxoStageYearWithLifeSkill[]
	onSuccessfulDownload?: () => void
}) => {
	const { preview } = useKontentHomeConfig()
	const refAbortController = useRef<AbortController>(null)
	const [selectedAssets, setSelectedAssets] = useState<ISimpleFile[]>([])
	const [isDownloading, setIsDownloading] = useState(false)
	const [errorMessageOnDownload, setErrorMessageOnDownload] = useState('')

	// computed
	const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024

	const selectedFiles = useMemo(() => {
		return selectedAssets.filter((file) => {
			// Then check if it matches all the filters
			if (
				resourceTypes.length > 0 &&
				file.resourceType &&
				!resourceTypes.includes(file.resourceType)
			)
				return false
			if (
				years.length > 0 &&
				file.years &&
				!years.some((year) => file.years.includes(year))
			)
				return false
			if (
				stages.length > 0 &&
				file.stages &&
				!filterAssetSelectedStages(
					stages as TaxoStage[],
					file.stages as TaxoStage[],
					file.syllabus_types,
				)
			)
				return false
			if (
				syllabuses.length > 0 &&
				file.syllabuses &&
				!syllabuses.some((syllabus) =>
					file.syllabuses.includes(syllabus),
				)
			)
				return false

			return true
		})
	}, [selectedAssets, resourceTypes, years, syllabuses])

	const selectedFileIds = useMemo(() => {
		return selectedFiles.map((file) => file.id)
	}, [selectedFiles])

	const totalSizeOfFiles = useMemo(
		() =>
			selectedFiles.reduce((acc, file) => {
				acc += file.size
				return acc
			}, 0),
		[selectedFiles],
	)

	const maxFileReached = useMemo(() => {
		return selectedFiles.length > 1 && totalSizeOfFiles > maxFileSizeInBytes
	}, [selectedFiles.length, totalSizeOfFiles, maxFileSizeInBytes])

	const scrollToAlertOnMaxFileReached = useCallback(() => {
		if (maxFileReached && alertEl) {
			alertEl.scrollIntoView({ behavior: 'smooth' })
			return
		}
	}, [maxFileReached, alertEl])

	const handleDownloadSelected = async () => {
		refAbortController.current = new AbortController()

		setIsDownloading(true)
		const [_, errorMessage] = await downloadCustomResource(
			{
				fileIds: selectedFileIds,
				syllabusName: downloadCustomResourceSyllabusName,
				isPreviewMode: preview,
				syllabuses,
				stages,
			},
			refAbortController.current.signal,
		)

		if (refAbortController.current?.signal?.aborted) {
			refAbortController.current = null
			return
		}
		if (errorMessage) {
			setErrorMessageOnDownload(errorMessage)
		} else {
			// Call the success callback if provided
			onSuccessfulDownload?.()
		}
		setIsDownloading(false)
	}

	const handleCancelDownloadSelected = () => {
		setIsDownloading(false)
		refAbortController.current.abort()
	}

	useEffect(() => {
		scrollToAlertOnMaxFileReached()
	}, [scrollToAlertOnMaxFileReached])

	return {
		selectedAssetIds: selectedFileIds,
		maxFileReached,
		maxFileSizeInMB,
		maxFileSizeInBytes,
		totalSizeOfFiles,
		errorMessageOnDownload,
		isDownloading,
		setSelectedAssets,
		setErrorMessageOnDownload,
		handleDownloadSelected,
		handleCancelDownloadSelected,
	}
}
