import { DEFAULT_NUQS_TRANSITION_OPTIONS } from '@/constants'
import { useQueryStates } from 'next-usequerystate'
import { TreeNodeProps } from 'react-dropdown-tree-select'

interface UseCommonDropdownChangeParams {
	setQS: ReturnType<typeof useQueryStates>[1]
	queryKey: string
	useParentNodes?: boolean
}

/**
 * A hook that returns a function to handle dropdown changes in tree multi-select components.
 * This is commonly used across resource, glossary, and advice pages.
 *
 * @param setQS - The setState function from useQueryStates hook
 * @param queryKey - The query string key to update
 * @param useParentNodes - Whether to use parent nodes instead of child nodes for the value
 * @returns A function that handles dropdown changes
 */
export const useCommonDropdownChange = ({
	setQS,
	queryKey,
	useParentNodes = false,
}: UseCommonDropdownChangeParams) => {
	return (
		_selectedNodes: TreeNodeProps[],
		_selectedChildNodes: TreeNodeProps[],
	) => {
		setQS(
			{
				[queryKey]:
					(useParentNodes ? _selectedNodes : _selectedChildNodes)
						.map((node) => node.value)
						.join(',') || null,
				p: null,
			},
			DEFAULT_NUQS_TRANSITION_OPTIONS,
		)
	}
}
