import { getApiDomain } from '@/lib/curriculum-azure-functions'
import {
	FetchResourcesParams,
	FetchResourcesResponse,
} from '@/types/fetchResourcesFromAzure'
import { commonFetch } from '@/utils/fetchUtils'

export const fetchResources = async ({
	keywords,
	syllabuses = [],
	keyLearningAreas = [],
	stages = [],
	years = [],
	page_size,
	page,
	codenames = [],
	resourcetypes = [],
	preview = false,
	sort_by = [],
}: FetchResourcesParams & {
	preview: boolean
}) => {
	return await commonFetch<FetchResourcesResponse, FetchResourcesParams>(
		`${getApiDomain(preview)}${
			process.env.NEXT_PUBLIC_DCAPI_PATH_TEACHING_RESOURCES
		}`,
		{
			keywords,
			syllabuses,
			keyLearningAreas,
			stages,
			years,
			page_size,
			page,
			codenames,
			resourcetypes,
			sort_by,
		},
	)
}
