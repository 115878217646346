import { getApiDomain } from '@/lib/curriculum-azure-functions'
import {
	FetchGlossariesParams,
	FetchGlossariesResponse,
} from '@/types/fetchGlossariesFromAzure'
import { commonFetch } from '@/utils/fetchUtils'

export const fetchGlossaries = async ({
	keywords,
	letters = [],
	syllabuses = [],
	type,
	page_size,
	page,
	codenames = [],
	preview = false,
}: FetchGlossariesParams) => {
	return await commonFetch<FetchGlossariesResponse, FetchGlossariesParams>(
		`${getApiDomain(preview)}${
			process.env.NEXT_PUBLIC_DCAPI_PATH_GLOSSARY
		}`,
		{
			keywords,
			letters,
			syllabuses,
			type,
			page_size,
			page,
			codenames,
		},
	)
}
