import NonFullWidthWrapper from '@/components/NonFullWidthWrapper'
import RichText from '@/components/RichText'
import UnknownComponent from '@/components/UnknownComponent'
import { GlossaryItemTagList } from '@/components/glossary/GlossaryItemTagList'
import { CommonCopyUrlWrapper } from '@/components/ui/copy-to-clipboard/CommonCopyUrlWrapper'
import { useCleanPathDefault } from '@/hooks/useCleanPathDefault'
import { Glossary } from '@/kontent/content-types'
import { CommonPageProps } from '@/types'
export default function GlossaryPage({
	data: { pageResponse },
}: CommonPageProps<Glossary>) {
	const currentUrl = useCleanPathDefault()

	const glossary = pageResponse?.item

	if (!glossary) {
		return (
			<UnknownComponent>Page does not have any content!</UnknownComponent>
		)
	}

	return (
		<>
			<CommonCopyUrlWrapper url={currentUrl} className="mb-4">
				<h1>
					{glossary.elements.title.value ||
						glossary.elements.title_system.value}
				</h1>
			</CommonCopyUrlWrapper>

			<NonFullWidthWrapper>
				<div
					key={glossary.system.id}
					className="glossary-body__definition"
					data-kontent-item-id={glossary.system.id}
				>
					<RichText
						richTextElement={glossary.elements.description}
						linkedItems={pageResponse.linkedItems}
						data-kontent-element-codename="description"
						mappings={[]}
					/>
					<GlossaryItemTagList
						className="mt-6"
						glossaryItem={glossary}
					/>
				</div>
			</NonFullWidthWrapper>
		</>
	)
}
