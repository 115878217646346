import DownloadListAzureData from '@/components/DownloadListAzureData'
import Icon from '@/components/Icon'
import { Loading } from '@/components/Loading'
import UnknownComponent from '@/components/UnknownComponent'
import { WrapperWithInView } from '@/components/WrapperWithInView'
import { ButtonSimple } from '@/components/nsw/button/ButtonSimple'
import { NswFilter } from '@/components/nsw/filters/NswFilter'
import { NswFilterCancel } from '@/components/nsw/filters/NswFilterCancel'
import { NswFilterItem } from '@/components/nsw/filters/NswFilterItem'
import { NswFilterList } from '@/components/nsw/filters/NswFilterList'
import { NswFormFieldset } from '@/components/nsw/filters/NswFormFieldset'
import { NswResultBar } from '@/components/nsw/filters/NswResultBar'
import { GridCol } from '@/components/nsw/grid/GridCol'
import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { TagListTreeMultiSelectNode } from '@/components/tree-multi-select/TagListTreeMultiSelectNode'
import { TagListTreeMultiSelectWrapper } from '@/components/tree-multi-select/TagListTreeMultiSelectWrapper'
import { TreeMultiSelect } from '@/components/tree-multi-select/TreeMultiSelect'
import { CommonCopyUrlWrapper } from '@/components/ui/copy-to-clipboard/CommonCopyUrlWrapper'
import {
	DEFAULT_NUQS_TRANSITION_OPTIONS,
	STAGEGROUPS_STAGES,
} from '@/constants'
import { COMMON_PAGE_SIZE_OPTIONS } from '@/constants/teaching_advice_resource'
import type { WpTeachingdviceResponseData } from '@/databuilders/wp_teachingadvice'
import { fetchAdvices } from '@/fetchers/fetchTeachingAdvices'
import { useCleanPathDefault } from '@/hooks/useCleanPathDefault'
import { useCommonDropdownChange } from '@/hooks/useCommonDropdownChange'
import { useDownloadSelectedFiles } from '@/hooks/useDownloadSelectedFiles'
import { useIsScreenDown } from '@/hooks/useIsScreenDown'
import { useKlaTreeNodeProps } from '@/hooks/useKlaTreeNodeProps'
import { useStageTreeNodeProps } from '@/hooks/useStageTreeNodeProps'
import { useToggle } from '@/hooks/useToggle'
import { useTreeMultiSelectSelected } from '@/hooks/useTreeMultiSelectSelected'
import { ResourceContainer } from '@/kontent/content-types'
import type { WpTeachingadvice as WpTeachingadviceModel } from '@/kontent/content-types/wp_teachingadvice'
import { TaxoStage, TaxoStageGroup, TaxoSyllabus } from '@/kontent/taxonomies'
import CustomModal from '@/legacy-ported/components/base/CustomModal'
import SearchBar from '@/legacy-ported/components/base/SearchBar'
import GeneratingOverlayCommon from '@/legacy-ported/components/document/overlay/GeneratingOverlayCommon'
import type {
	AssetWithRawElements,
	CommonPageProps,
	TaxoStageWithLifeSkill,
	TaxoStageYearWithLifeSkill,
} from '@/types'
import { WebLinkTeachingadviceExtended } from '@/types/customKontentTypes'
import {
	DownloadListAzureDataProps,
	DownloadListAzureField,
} from '@/types/downloadListAzureData'
import { FetchAdvicesParams } from '@/types/fetchAdvicesFromAzure'
import { byTaxoCodename, getArrayLength } from '@/utils'
import { getSelectedNodesFromData } from '@/utils/tree-multi-select'
import { isResourceContainer } from '@/utils/type_predicates'
import type { ElementModels } from '@kontent-ai/delivery-sdk'
import { useQuery } from '@tanstack/react-query'
import animateScrollTo from 'animated-scroll-to'
import clsx from 'clsx'
import { queryTypes, useQueryStates } from 'next-usequerystate'
import { Alert, Button } from 'nsw-ds-react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { TreeNodeProps } from 'react-dropdown-tree-select'

type TeachingAdvicePageItem =
	| WebLinkTeachingadviceExtended
	| AssetWithRawElements
	| ResourceContainer

export const styleCardTagListOnHover = {
	'&:hover .nsw-tag, &:focus .nsw-tag': {
		borderColor: '#fff',
		color: '#fff',
	},
}

export const makeStageGroupOptions = (
	stageGroups: ElementModels.TaxonomyTerm<TaxoStageGroup>[],
	stages: ElementModels.TaxonomyTerm<TaxoStage>[],
	withLifeSkillsOnSecondaryAndSenior = false,
) => {
	const secondaryAndSenior: TaxoStageGroup[] = ['secondary', 'senior']
	return stageGroups.map((stageGroup) => {
		const shouldIncludeLifeSkills =
			withLifeSkillsOnSecondaryAndSenior &&
			secondaryAndSenior.includes(stageGroup.codename)
		const children = STAGEGROUPS_STAGES[stageGroup.codename]
			.map((stageCodename) => {
				const stage = stages.find((s) => {
					return s.codename === stageCodename
				})
				if (!stage) return undefined
				return {
					id: stage.codename,
					label: stage.name,
				}
			})
			.filter((item) => !!item)

		return {
			id: stageGroup.codename,
			label: stageGroup.name,
			children: [
				...children,
				shouldIncludeLifeSkills && {
					id: `life_skills@${children.map((s) => s.id).join('___')}`,
					label: `Life Skills (${stageGroup.name.split(' ')[0]})`,
				},
			].filter(Boolean),
		}
	})
}

function WpTeachingadvice(
	props: CommonPageProps<WpTeachingadviceModel, WpTeachingdviceResponseData>,
) {
	const isScreenDownLg = useIsScreenDown('lg')
	const { preview } = props
	const {
		pageResponse,
		stages,
		keyLearningAreas,
		// focusAreasOrOptionlists,
		syllabuses,
		stageGroups,
		config,
	} = props.data
	const refFirstLoad = useRef(true)

	const [qs, setQS] = useQueryStates({
		q: queryTypes.string.withDefault(''), // search query
		s: queryTypes.string.withDefault(''), // syllabuses, e.g. english_advanced_11_12_2024,english_eald_11_12_2024
		ps: queryTypes.integer.withDefault(COMMON_PAGE_SIZE_OPTIONS[1]), // page size,
		stage: queryTypes.string.withDefault(''), // stages, e.g. life_skills@primary___stage_3
		year: queryTypes.string.withDefault(''), // years, e.g. 2024
		p: queryTypes.integer.withDefault(1), // page number
		code: queryTypes.string.withDefault(''),
		sort: queryTypes.string.withDefault(''),
	})

	const selectedQsSyllabus = qs.s ? qs.s.split(',') : []
	const selectedQsStage = qs.stage ? qs.stage.split(',') : []
	const selectedQsYear = qs.year ? qs.year.split(',') : []
	const selectedSort = useMemo(
		() =>
			qs.sort
				? (qs.sort.split(',').map((sortModelStr) => {
						//sort model str could be 'field_name_sort', e.g. 'resource_type_asc'
						// get direction
						const direction = sortModelStr.split('_').pop()
						// get field
						const field = sortModelStr.replace(`_${direction}`, '')

						return { field, direction }
				  }) as FetchAdvicesParams['sort_by'])
				: [],
		[qs.sort],
	)
	const selectedSortModel = useMemo<
		DownloadListAzureDataProps['initialSortModel']
	>(() => {
		return selectedSort.length
			? selectedSort.map((sort) => ({
					field: sort.field as DownloadListAzureField,
					sort: sort.direction,
			  }))
			: [
					{
						field: 'officialreleasedate' as DownloadListAzureField,
						sort: 'desc',
					},
			  ]
	}, [selectedSort])

	const currentUrl = useCleanPathDefault()
	const qPageInt = qs.p ? qs.p : 1
	const page = pageResponse.item
	const refScrollHereAfterFilter = useRef<HTMLDivElement>(null)

	const klaOptions = useKlaTreeNodeProps({
		disabledKlas: config.item.elements.disabled_key_learning_areas,
		keyLearningAreas,
		syllabuses: syllabuses.items,
		selectedSyllabus: selectedQsSyllabus as TaxoSyllabus[],
	})

	const stageOptions = useStageTreeNodeProps({
		stageGroups,
		stages,
		selectedStages: selectedQsStage as TaxoStage[],
		withLifeSkillsOnSecondaryAndSenior: true,
	})

	// from the klaOptions, find the child nodes that are selected. Match with selectedQsSyllabus
	const selectedKlaOptionsChildNodes = klaOptions
		.flatMap((kla) => {
			return kla.children.flatMap((child) => {
				if (selectedQsSyllabus.includes(child.value)) {
					return child
				}
				return null
			})
		})
		.filter(Boolean)

	const selectedStageOptionsChildNodes = stageOptions
		.flatMap((stage) => {
			return stage.children.flatMap((child) => {
				if (selectedQsStage.includes(child.value)) {
					return child
				}
				return null
			})
		})
		.filter(Boolean)

	const {
		selectedChildNodesValues: selectedFilterSyllabus,
		handleDrodpownChange: handleSyllabusesFilterChange,
		handleRemoveNode: handleRemoveNodeSyllabus,
		handleReset: handleResetSyllabus,
	} = useTreeMultiSelectSelected<TaxoSyllabus>({
		initialSelectedChildNodes: selectedKlaOptionsChildNodes,
		additionalFnOnDropdownChange: useCommonDropdownChange({
			setQS,
			queryKey: 's',
		}),
	})

	const {
		selectedChildNodesValues: selectedFilterStages,
		handleDrodpownChange: handleStagesFilterChange,
		handleRemoveNode: handleRemoveNodeStage,
		handleReset: handleResetStages,
	} = useTreeMultiSelectSelected<TaxoStage>({
		initialSelectedChildNodes: selectedStageOptionsChildNodes,
		additionalFnOnDropdownChange: useCommonDropdownChange({
			setQS,
			queryKey: 'stage',
		}),
	})

	const [isFilterShown, toggleIsFilterShown] = useToggle(true)

	const {
		data: advicesResponse,
		isFetchedAfterMount: isFetchedAdvices,
		isFetching: isFetchingAdvices,
	} = useQuery({
		queryKey: [
			qs.q,
			selectedQsSyllabus,
			selectedQsStage,
			selectedQsYear,
			qs.ps,
			qs.p,
			qs.sort,
		],
		queryFn: async () => {
			return fetchAdvices({
				keywords: qs.q || null,
				syllabuses: selectedQsSyllabus as TaxoSyllabus[],
				keyLearningAreas: [],
				page_size: qs.ps || null,
				page: qs.p || null,
				stages: selectedQsStage as TaxoStageWithLifeSkill[],
				years: selectedQsYear as TaxoStageYearWithLifeSkill[],
				sort_by: selectedSort,
				preview,
			})
		},
		keepPreviousData: true,
		staleTime: 0,
	})

	const selectedFilterTagsSyllabus = useMemo(() => {
		return getSelectedNodesFromData(klaOptions)
	}, [klaOptions])
	const selectedFilterTagsStage = useMemo(() => {
		return getSelectedNodesFromData(stageOptions)
	}, [stageOptions])

	const filteredAdvices = isFetchedAdvices ? advicesResponse?.json.data : []

	const simpleFilesOfFilteredResources = useMemo(() => {
		return filteredAdvices.filter(isResourceContainer).map((resource) => {
			return {
				id: resource.system.id,
				size: resource.filesize,
				resourceType: resource.resource_type.codename,
				year: resource.years.map(byTaxoCodename),
				syllabus: resource.syllabuses.map((s) => s.taxonomy),
			}
		})
	}, [filteredAdvices])

	const {
		errorMessageOnDownload,
		selectedAssetIds,
		isDownloading,
		maxFileReached,
		maxFileSizeInMB,
		setSelectedAssets,
		setErrorMessageOnDownload,
		handleDownloadSelected: originalHandleDownloadSelected,
		handleCancelDownloadSelected,
	} = useDownloadSelectedFiles({
		alertEl: refScrollHereAfterFilter.current,
		files: simpleFilesOfFilteredResources,
		downloadCustomResourceSyllabusName: 'teaching advice',
		onSuccessfulDownload: () => {
			setSelectedAssets([])
		},
	})

	// Wrap the original handleDownloadSelected to clear selection after download
	const handleDownloadSelected = async () => {
		await originalHandleDownloadSelected()
	}

	const hiddenFields = useMemo<DownloadListAzureField[]>(() => {
		const defaultHiddens: DownloadListAzureField[] = [
			'fileType',
			'resource_type',
			'year',
			'fileSize',
		]
		if (isScreenDownLg) {
			return [
				...defaultHiddens,
				'syllabuses',
				'stages',
				'officialreleasedate',
			]
		}
		return isFilterShown
			? [...defaultHiddens, 'stages', 'officialreleasedate']
			: [...defaultHiddens, 'info']
	}, [isFilterShown, isScreenDownLg])

	const scrollToTopOfRightCol = () => {
		if (refScrollHereAfterFilter.current) {
			animateScrollTo(refScrollHereAfterFilter.current, {
				speed: 0,
				verticalOffset: -24,
			})
		}
	}

	const resetPageAndScroll = useCallback(
		async (pageNumber = 0) => {
			// the `pageNumber` is 0-based
			scrollToTopOfRightCol()
			setQS(
				{
					p: pageNumber ? pageNumber + 1 : null,
				},
				DEFAULT_NUQS_TRANSITION_OPTIONS,
			)
		},
		[setQS],
	)

	const handleReset = () => {
		// Reset URL query parameters
		setQS(
			{
				q: null,
				s: null,
				stage: null,
				year: null,
				p: null,
				ps: null,
				sort: null,
			},
			DEFAULT_NUQS_TRANSITION_OPTIONS,
		)

		// Reset internal states
		handleResetSyllabus()
		handleResetStages()
	}

	const handleSortModelChange = (sortModel) => {
		setQS(
			{
				sort: sortModel.map((s) => `${s.field}_${s.sort}`).join(','),
			},
			DEFAULT_NUQS_TRANSITION_OPTIONS,
		)
	}

	const handleRemoveTagClick = useCallback(
		(
				queryKey: string,
				selectedFilterState: (TaxoStage | TaxoSyllabus)[],
				handleRemoveNodeFn: (_removedNode: TreeNodeProps) => void,
			) =>
			(_e, removedNode) => {
				// check if the removed node is a child node or a parent node
				// if it is a parent node, remove all the child nodes
				// if it is a child node, remove only the child node

				const _removedNodes =
					removedNode.children?.length > 0
						? removedNode.children.map((n) => n.value)
						: [removedNode.value]

				setQS(
					{
						[queryKey]:
							selectedFilterState
								.filter((l) => !_removedNodes.includes(l))
								.join(',') || null,
						p: null,
					},
					DEFAULT_NUQS_TRANSITION_OPTIONS,
				)
				handleRemoveNodeFn(removedNode)
			},
		[setQS],
	)

	useEffect(() => {
		if (isFetchedAdvices && !refFirstLoad.current) {
			scrollToTopOfRightCol()
		}
		if (isFetchedAdvices) {
			refFirstLoad.current = false
		}
	}, [isFetchedAdvices])

	if (!page) {
		return (
			<UnknownComponent>
				Page {page.system.codename} does not have any content!
			</UnknownComponent>
		)
	}

	return (
		<>
			{page.elements.title.value && (
				<CommonCopyUrlWrapper url={currentUrl} className="mb-8">
					<h1
						data-kontent-item-id={page.system.id}
						data-kontent-element-codename="title"
					>
						{page.elements.title.value}
					</h1>
				</CommonCopyUrlWrapper>
			)}

			<div ref={refScrollHereAfterFilter} className="my-8">
				<div className="flex flex-wrap gap-4 lg:gap-8">
					<div className="flex-1 basis-full lg:basis-0 max-w-3xl">
						<SearchBar
							initialSearchText={qs.q}
							onSearch={(text) => {
								setQS(
									{
										q: text || null,
										p: null,
									},
									DEFAULT_NUQS_TRANSITION_OPTIONS,
								)
							}}
							value={qs.q}
							disableResetSearchText
							debounceTime={300}
						/>
					</div>
					<label className="flex items-center gap-3 !mr-0">
						<span className="flex-shrink-0 nsw-form__label">
							Results per page:
						</span>
						<select
							className="nsw-form__select max-w-[200px] !mt-0"
							onChange={(e) => {
								const value = parseInt(e.target.value)
								setQS(
									{
										p: null,
										ps:
											value ===
											COMMON_PAGE_SIZE_OPTIONS[1]
												? null
												: value,
									},
									DEFAULT_NUQS_TRANSITION_OPTIONS,
								)
							}}
							value={qs.ps}
							autoComplete="off"
						>
							{COMMON_PAGE_SIZE_OPTIONS.map((option) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</select>
					</label>
				</div>
			</div>

			<div className="my-8">
				<GridWrapper className="!mt-0">
					<GridCol
						lg={3}
						className={clsx('!pt-0', {
							'lg:hidden': !isFilterShown,
						})}
					>
						<NswFilter
							className={clsx(
								String`[&_.nsw-filters\_\_title]:border-t [&_.nsw-filters\_\_title]:border-t-nsw-grey-04`,
							)}
							css={{
								'& > .nsw-filters__controls': {
									borderBottom: 0,
									paddingTop: 0,
									paddingBottom: '2rem',
								},
								'& > .nsw-nsw-form__fieldset': {
									padding: 0,
								},
								'& .nsw-form__legend': {
									paddingBottom: 12,
								},
							}}
							totalItems={advicesResponse?.json.total_records}
							mobileToggleFilterLabel="Show filters"
							title={
								<ButtonSimple
									onClick={toggleIsFilterShown}
									slotBeforeChildren={
										<Icon
											icon="ic:baseline-close"
											width={24}
											height={24}
											className="block p-0.5 bg-nsw-brand-dark text-white rounded-[4px]"
										/>
									}
								>
									Hide filters
								</ButtonSimple>
							}
						>
							<NswFilterList>
								<NswFilterItem>
									<NswFormFieldset title="Stages">
										<TreeMultiSelect
											key={stageOptions
												.map((r) => r.value)
												.join(',')}
											data={stageOptions}
											onChange={handleStagesFilterChange}
											placeholder="Select stages"
											texts={{
												label: 'Select stages',
											}}
											hideTagsSelected
										/>
									</NswFormFieldset>
								</NswFilterItem>
								<NswFilterItem>
									<NswFormFieldset title="Learning areas and syllabuses">
										<TreeMultiSelect
											key={klaOptions
												.map((r) => r.value)
												.join(',')}
											data={klaOptions}
											onChange={
												handleSyllabusesFilterChange
											}
											placeholder="Select syllabuses"
											texts={{
												label: 'Select syllabuses',
											}}
											hideTagsSelected
										/>
									</NswFormFieldset>
								</NswFilterItem>
							</NswFilterList>
							<NswFilterCancel onReset={handleReset} />
						</NswFilter>
					</GridCol>
					<GridCol lg={isFilterShown ? 9 : 12} className="!pt-0">
						<div className="relative lg:border-t border-nsw-grey-04">
							{(selectedFilterTagsSyllabus.length > 0 ||
								selectedFilterTagsStage.length > 0) && (
								<TagListTreeMultiSelectWrapper
									className={clsx({
										'py-8 border-b border-nsw-grey-04':
											selectedFilterTagsStage.length >
												0 ||
											selectedFilterTagsSyllabus.length >
												0,
									})}
									showClearButton={
										getArrayLength(
											selectedFilterTagsStage,
										) +
											getArrayLength(
												selectedFilterTagsSyllabus,
											) >
										1
									}
									onClearClick={handleReset}
								>
									{selectedFilterTagsStage.length > 0 && (
										<TagListTreeMultiSelectNode
											list={selectedFilterTagsStage}
											onRemoveClick={handleRemoveTagClick(
												'stage',
												selectedFilterStages,
												handleRemoveNodeStage,
											)}
										/>
									)}
									{selectedFilterTagsSyllabus.length > 0 && (
										<TagListTreeMultiSelectNode
											list={selectedFilterTagsSyllabus}
											onRemoveClick={handleRemoveTagClick(
												's',
												selectedFilterSyllabus,
												handleRemoveNodeSyllabus,
											)}
										/>
									)}
								</TagListTreeMultiSelectWrapper>
							)}
							<NswResultBar
								className={clsx('flex-wrap gap-3 !pt-6')}
								css={{
									'&&': {
										marginTop: 0,
										alignItems: 'center',
									},
									'.NswResultBar__result': {
										display: 'flex',
										alignItems: 'center',
										gap: '1rem',
									},
									'.nsw-results-bar__info': {
										flex: '1 0 100%',
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap',
										gap: 16,
									},
									'.nsw-results-bar__sorting': {
										paddingTop: 0,
										width: 1,
										overflow: 'hidden',
									},
								}}
								page={qPageInt}
								pageSize={advicesResponse?.json.page_size}
								total={advicesResponse?.json.total_records}
								slotShowingResultsAfter={
									advicesResponse?.json.total_records > 0 && (
										<Button
											className="flex items-center gap-2 w-full lg:w-auto justify-center"
											onClick={handleDownloadSelected}
											disabled={
												!selectedAssetIds.length ||
												isDownloading ||
												maxFileReached
											}
										>
											<span className="mr-2">
												Download selected
												{selectedAssetIds.length > 0 &&
													` (${selectedAssetIds.length})`}
											</span>
											<Icon icon="bxs:download" />
										</Button>
									)
								}
								slotShowingResultsNumberBefore={
									!isFilterShown && (
										<ButtonSimple
											className={clsx(
												'border-r border-r-nsw-grey-03 pr-4',
											)}
											onClick={toggleIsFilterShown}
											slotBeforeChildren={
												<Icon
													icon="mdi:tune"
													width={24}
													height={24}
													className="block p-0.5 bg-nsw-brand-dark text-white rounded-[4px]"
												/>
											}
										>
											Show filters
										</ButtonSimple>
									)
								}
							></NswResultBar>

							{advicesResponse?.json.data.length > 0 ? (
								<>
									<div className="flex gap-3 align-top justify-between mb-4 flex-wrap md:flex-nowrap">
										<WrapperWithInView>
											{(inView) => (
												<>
													<div className="flex-shrink-0 md:ml-auto"></div>
													<div
														className={clsx(
															'fixed top-0 left-0 bg-white z-10 py-3 w-full shadow-[0_4px_12px_0_rgba(0,0,0,.3)] transition duration-300',
															{
																'pointer-events-none opacity-0':
																	inView ||
																	!selectedAssetIds.length,
																'top-[26px]':
																	preview,
															},
														)}
														aria-hidden={
															inView ||
															!selectedAssetIds.length
																? 'true'
																: 'false'
														}
													>
														<div className="nsw-container flex justify-end">
															<Button
																onClick={
																	handleDownloadSelected
																}
																disabled={
																	!selectedAssetIds.length ||
																	isDownloading ||
																	maxFileReached
																}
															>
																<span className="mr-2">
																	Download
																	selected
																	{selectedAssetIds.length >
																		0 &&
																		` (${selectedAssetIds.length})`}
																</span>
																<Icon icon="bxs:download" />
															</Button>
														</div>
													</div>
												</>
											)}
										</WrapperWithInView>
									</div>

									{maxFileReached && (
										<Alert
											title="Warning"
											as="warning"
											className="mb-3"
										>
											<p>
												Maximum of {maxFileSizeInMB} MB
												reached
											</p>
										</Alert>
									)}

									{isFetchedAdvices && advicesResponse?.json && (
										<DownloadListAzureData
											page={qPageInt - 1}
											pageSize={
												advicesResponse.json.page_size
											}
											files={filteredAdvices}
											hiddenFields={hiddenFields}
											onSelectedFiles={(
												_selectedFiles,
											) => {
												setSelectedAssets(
													(prevFiles) => {
														// Get IDs of files on current page
														const currentPageIds =
															new Set(
																filteredAdvices.map(
																	(f) =>
																		f.system
																			.id,
																),
															)

														// Keep previously selected files not on current page
														const remainingPrevFiles =
															prevFiles.filter(
																(f) =>
																	!currentPageIds.has(
																		f.id,
																	),
															)

														// Get newly selected files from simpleFilesOfFilteredResources
														const newlySelectedFiles =
															_selectedFiles.map(
																(file) => {
																	const matchingFile =
																		simpleFilesOfFilteredResources.find(
																			(
																				f,
																			) =>
																				f.id ===
																				file.id,
																		)

																	// check prevFiles.
																	const matchingPreviousFile =
																		prevFiles.find(
																			(
																				f,
																			) =>
																				f.id ===
																				file.id,
																		)
																	return (
																		matchingFile ||
																		matchingPreviousFile ||
																		file
																	)
																},
															)

														// Combine and deduplicate
														return Array.from(
															new Map(
																[
																	...remainingPrevFiles,
																	...newlySelectedFiles,
																].map(
																	(item) => [
																		item.id,
																		item,
																	],
																),
															).values(),
														)
													},
												)
											}}
											onPageChange={resetPageAndScroll}
											isIncludeCopyUrlOnTitle
											showNextToTitleTooltip={
												isScreenDownLg || isFilterShown
											}
											showFileSizeUnderTitle
											initialSortModel={selectedSortModel}
											selectionModel={selectedAssetIds}
											rowCount={
												advicesResponse.json
													.total_records
											}
											paginationMode="server"
											showSelectAllCheckbox
											keepNonExistentRowsSelected
											onSortModelChange={
												handleSortModelChange
											}
											sortingMode="server"
										/>
									)}
								</>
							) : isFetchingAdvices ? null : (
								<h4 className="text-center mt-20">
									{/* eslint-disable-next-line quotes */}
									{"We didn't find any results. "}
									<button
										type="reset"
										className="underline bold nsw-text--brand-dark"
										onClick={handleReset}
									>
										Clear all filters
									</button>
								</h4>
							)}

							{isFetchingAdvices && (
								<div className="bg-white/90 absolute inset-0 w-full flex justify-center pt-6">
									<Loading />
								</div>
							)}
						</div>
					</GridCol>
				</GridWrapper>
			</div>
			<GeneratingOverlayCommon
				modalStatus={isDownloading}
				handleCancel={handleCancelDownloadSelected}
			/>
			{errorMessageOnDownload && (
				<CustomModal
					title="Error"
					modalStatus={!!errorMessageOnDownload}
					handleCancel={() => setErrorMessageOnDownload('')}
					hideConfirmButton
				>
					<p>{errorMessageOnDownload}</p>
				</CustomModal>
			)}
		</>
	)
}

export default WpTeachingadvice
