import { getApiDomain } from '@/lib/curriculum-azure-functions'
import {
	FetchAdvicesParams,
	FetchAdvicesResponse,
} from '@/types/fetchAdvicesFromAzure'
import { commonFetch } from '@/utils/fetchUtils'

export const fetchAdvices = async ({
	keywords,
	syllabuses = [],
	keyLearningAreas = [],
	stages = [],
	years = [],
	page_size,
	page,
	codenames = [],
	preview = false,
	sort_by = [],
}: FetchAdvicesParams & {
	preview: boolean
}) => {
	return await commonFetch<FetchAdvicesResponse, FetchAdvicesParams>(
		`${getApiDomain(preview)}${
			process.env.NEXT_PUBLIC_DCAPI_PATH_TEACHING_ADVICES
		}`,
		{
			keywords,
			syllabuses,
			keyLearningAreas,
			stages,
			years,
			page_size,
			page,
			codenames,
			sort_by,
		},
	)
}
