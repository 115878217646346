import { DownloadListProps } from '@/types/downloadList'

export const WP_TA_RESOURCE_PAGE_SIZE = 20 as const
export const WP_TA_RESOURCE_SORT_MODEL: DownloadListProps['initialSortModel'] =
	[
		{
			field: 'date',
			sort: 'desc',
		},
	]
export const COMMON_PAGE_SIZE_OPTIONS = [10, 20, 50, 100] as const
