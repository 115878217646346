import Icon from '@/components/Icon'
import { Loading } from '@/components/Loading'
import RichText from '@/components/RichText'
import UnknownComponent from '@/components/UnknownComponent'
import { GlossaryItemTagList } from '@/components/glossary/GlossaryItemTagList'
import Pagination from '@/components/nsw/Pagination'
import { NswFilter } from '@/components/nsw/filters/NswFilter'
import { NswFilterCancel } from '@/components/nsw/filters/NswFilterCancel'
import { NswFilterItem } from '@/components/nsw/filters/NswFilterItem'
import { NswFilterList } from '@/components/nsw/filters/NswFilterList'
import { NswFormFieldset } from '@/components/nsw/filters/NswFormFieldset'
import { NswResultBar } from '@/components/nsw/filters/NswResultBar'
import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { TagListTreeMultiSelectNode } from '@/components/tree-multi-select/TagListTreeMultiSelectNode'
import { TagListTreeMultiSelectWrapper } from '@/components/tree-multi-select/TagListTreeMultiSelectWrapper'
import { TreeMultiSelect } from '@/components/tree-multi-select/TreeMultiSelect'
import { CommonCopyUrlWrapper } from '@/components/ui/copy-to-clipboard/CommonCopyUrlWrapper'
import { DEFAULT_NUQS_TRANSITION_OPTIONS } from '@/constants'
import { WpGlossaryResponseData } from '@/databuilders/wp_glossary'
import { fetchGlossaries } from '@/fetchers/fetchGlossaries'
import { useCleanPathDefault } from '@/hooks/useCleanPathDefault'
import { useCommonDropdownChange } from '@/hooks/useCommonDropdownChange'
import { useKlaTreeNodeProps } from '@/hooks/useKlaTreeNodeProps'
import { useTreeMultiSelectSelected } from '@/hooks/useTreeMultiSelectSelected'
import type { WpGlossary as WpGlossaryModel } from '@/kontent/content-types/wp_glossary'
import { TaxoStage, TaxoSyllabus } from '@/kontent/taxonomies'
import CustomModal from '@/legacy-ported/components/base/CustomModal'
import SearchBar from '@/legacy-ported/components/base/SearchBar'
import GeneratingOverlayCommon from '@/legacy-ported/components/document/overlay/GeneratingOverlayCommon'
import type { CommonPageProps } from '@/types'
import { FetchGlossariesResponse } from '@/types/fetchGlossariesFromAzure'
import { getArrayLength, isRichtextEmpty, sanitizeIdAttribute } from '@/utils'
import convertLinkedItemsArrayToRecordFromAzure from '@/utils/convertModularContentArrayToRecordFromAzure'
import { downloadGlossaries } from '@/utils/downloadGlossaries'
import { normalizeRichtextElementFromAzure } from '@/utils/richtextFromAzureUtils'
import { getSelectedNodesFromData } from '@/utils/tree-multi-select'
import Grid from '@mui/material/Grid'
import { useQuery } from '@tanstack/react-query'
import animateScrollTo from 'animated-scroll-to'
import clsx from 'clsx'
import { queryTypes, useQueryStates } from 'next-usequerystate'
import {
	Accordion,
	AccordionGroup,
	FormGroupRadio,
	Button as NswButton,
} from 'nsw-ds-react'
import { FormOption } from 'nsw-ds-react/dist/component/forms'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TreeNodeProps } from 'react-dropdown-tree-select'
import slugify from 'slugify'
const AtoZArray = [
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z',
]

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
const GlossaryFilters = ({
	selectedLetters,
	lettersCount = [],
	onSelect,
}: {
	selectedLetters: string[]
	lettersCount?: FetchGlossariesResponse['letters']
	onSelect: (_letter: string) => void
}) => {
	const handleClick = (e) => {
		onSelect(e.currentTarget.dataset.letter)
	}
	return (
		<div className="flex flex-wrap gap-0.5">
			{AtoZArray.map((letter) => {
				const isSelected = selectedLetters.includes(letter)
				const letterCount = lettersCount?.find(
					(lc) => lc.letter == letter,
				)
				return (
					<NswButton
						key={letter}
						onClick={handleClick}
						data-letter={letter}
						css={{
							borderWidth: 2,
							'&&': {
								padding: '8px 16px',
								width: 43,
								height: 40,
								display: 'inline-flex',
							},
						}}
						className={'nsw-button--flex uppercase'}
						style={isSelected ? 'dark' : 'dark-outline'}
						disabled={
							letterCount ? letterCount.count == 0 : undefined
						}
					>
						{letter}
					</NswButton>
				)
			})}
		</div>
	)
}

const TERM_OPTIONS: FormOption[] = [
	{
		text: 'All glossary terms',
		value: '',
	},
	{
		text: 'ACE terms only',
		value: 'ace',
	},
	{
		text: 'Assessment terms only',
		value: 'assessment',
	},
]

function WpGlossary(
	props: CommonPageProps<WpGlossaryModel, WpGlossaryResponseData>,
) {
	const currentUrl = useCleanPathDefault()
	const { mappings, preview } = props
	const {
		config,
		pageResponse,
		syllabuses: allSyllabuses,
		keyLearningAreas,
	} = props.data
	const refFirstLoad = useRef(true)
	const refScrollToAfterFilter = useRef<HTMLDivElement>(null)
	const refAbortController = useRef<AbortController>(null)
	const page = pageResponse.item

	// query strings
	const [qs, setQS] = useQueryStates({
		t: queryTypes.string.withDefault(''), //terms type
		q: queryTypes.string.withDefault(''), // search query
		l: queryTypes.string.withDefault(''), // letters, e.g. a,b,c
		s: queryTypes.string.withDefault(''), // syllabuses, e.g. english_advanced_11_12_2024,english_eald_11_12_2024
		ps: queryTypes.integer.withDefault(10), // page size,
		p: queryTypes.integer.withDefault(1), // page number
		code: queryTypes.string.withDefault(''),
	})
	const [isDownloading, setIsDownloading] = useState(false)
	const [errorPopupMessage, setErrorPopupMessage] = useState('')

	const selectedLetters = useMemo(() => (qs.l ? qs.l.split(',') : []), [qs.l])
	const selectedTerm = qs.code
	const selectedTermType = qs.t || ''
	const selectedQsSyllabus = qs.s ? qs.s.split(',') : []

	const {
		data: glossariesResponse,
		isFetchedAfterMount: isFetchedGlossaries,
		isFetching: isFetchingGlossaries,
	} = useQuery({
		queryKey: [
			'all-glossaries',
			selectedTermType,
			qs.q,
			qs.l,
			qs.s,
			qs.ps,
			qs.p,
			preview,
		],
		queryFn: async () => {
			return fetchGlossaries({
				keywords: qs.q || '',
				letters: selectedLetters,
				syllabuses: selectedQsSyllabus,
				type: selectedTermType,
				page_size: qs.ps || 10,
				page: qs.p || 1,
				preview,
			})
		},
		keepPreviousData: true,
		staleTime: 0,
	})

	const klaOptions = useKlaTreeNodeProps({
		disabledKlas: config.item.elements.disabled_key_learning_areas,
		keyLearningAreas,
		syllabuses: allSyllabuses.items,
		selectedSyllabus: selectedQsSyllabus as TaxoSyllabus[],
	})

	// from the klaOptions, find the child nodes that are selected. Match with selectedQsSyllabus
	const selectedKlaOptionsChildNodes = klaOptions
		.flatMap((kla) => {
			return kla.children.flatMap((child) => {
				if (selectedQsSyllabus.includes(child.value)) {
					return child
				}
				return null
			})
		})
		.filter(Boolean)

	const {
		selectedChildNodesValues: selectedFilterSyllabus,
		handleDrodpownChange: handleSyllabusesFilterChange,
		handleRemoveNode: handleRemoveNodeSyllabus,
		handleReset: handleResetSelectedFilterSyllabus,
	} = useTreeMultiSelectSelected<TaxoSyllabus>({
		initialSelectedChildNodes: selectedKlaOptionsChildNodes,
		additionalFnOnDropdownChange: useCommonDropdownChange({
			setQS,
			queryKey: 's',
		}),
	})

	// terms options
	const termOptions = useMemo(() => {
		return TERM_OPTIONS.map((option) => ({
			...option,
			selected: option.value === (qs.t || ''),
		}))
	}, [qs.t])

	// selected term option to tag
	const termSelectedTreeNodeProps: TreeNodeProps[] | null = useMemo(() => {
		const termSelected = selectedTermType
			? TERM_OPTIONS.find((option) => option.value === selectedTermType)
			: null
		return termSelected
			? [
					{
						text: termSelected.text,
						value: termSelected.value as string,
						// take the first word
						label: termSelected.text.split(' ')[0],
					},
			  ]
			: null
	}, [selectedTermType])

	const selectedFilterTagsSyllabus = useMemo(() => {
		return getSelectedNodesFromData(klaOptions)
	}, [klaOptions])

	const scrollToTopOfRightCol = () => {
		if (refScrollToAfterFilter.current) {
			animateScrollTo(refScrollToAfterFilter.current, {
				speed: 0,
				verticalOffset: -24,
			})
		}
	}

	// Methods
	const handleReset = () => {
		handleResetSelectedFilterSyllabus()
		setQS(
			{
				t: null, //terms type
				q: null, // search query
				l: null, // letters, e.g. a,b,c
				s: null, // syllabuses, e.g. english_advanced_11_12_2024,english_eald_11_12_2024
				ps: null, // page size,
				p: null, // page number
				code: null,
			},
			DEFAULT_NUQS_TRANSITION_OPTIONS,
		)
	}

	const handleRemoveTagClick = useCallback(
		(
				queryKey: string,
				selectedFilterState: (TaxoStage | TaxoSyllabus)[],
				handleRemoveNodeFn: (_removedNode: TreeNodeProps) => void,
			) =>
			(_e, removedNode) => {
				// check if the removed node is a child node or a parent node
				// if it is a parent node, remove all the child nodes
				// if it is a child node, remove only the child node

				const _removedNodes =
					removedNode.children?.length > 0
						? removedNode.children.map((n) => n.value)
						: [removedNode.value]

				setQS(
					{
						[queryKey]:
							selectedFilterState
								.filter((l) => !_removedNodes.includes(l))
								.join(',') || null,
						p: null,
					},
					DEFAULT_NUQS_TRANSITION_OPTIONS,
				)
				handleRemoveNodeFn(removedNode)
			},
		[setQS],
	)

	const handleDownload = async () => {
		refAbortController.current = new AbortController()
		setIsDownloading(true)

		const [_, errorMessage] = await downloadGlossaries(
			{
				keywords: qs.q || '',
				letters: selectedLetters,
				syllabuses: selectedFilterSyllabus,
				type: qs.t || '',
				isPreviewMode: preview,
			},
			refAbortController.current.signal,
		)
		if (refAbortController.current?.signal?.aborted) return
		if (errorMessage) {
			setErrorPopupMessage(errorMessage)
		}

		setIsDownloading(false)
	}

	const handleCancelDownload = () => {
		refAbortController.current.abort()
		refAbortController.current = null
		setIsDownloading(false)
	}

	useEffect(() => {
		if (isFetchedGlossaries && !refFirstLoad.current) {
			scrollToTopOfRightCol()
		}
		if (isFetchedGlossaries) {
			refFirstLoad.current = false
		}
	}, [isFetchedGlossaries])

	if (!page) {
		return (
			<UnknownComponent>
				Page {page.system.codename} does not have any content!
			</UnknownComponent>
		)
	}

	return (
		<>
			<GridWrapper
				className="-mt-8"
				spacing={{
					xs: 4,
					lg: 8,
				}}
				rowGap={{
					xs: 0,
					lg: 0,
				}}
			>
				<Grid item xs={12} className="order-1 lg:order-[initial]">
					{page.elements.title.value && (
						<CommonCopyUrlWrapper url={currentUrl} className="mb-8">
							<h1
								data-kontent-item-id={page.system.id}
								data-kontent-element-codename="title"
							>
								{page.elements.title.value}
							</h1>
						</CommonCopyUrlWrapper>
					)}
					{!isRichtextEmpty(
						page.elements.web_content_rtb__content.value,
					) && (
						<RichText
							mappings={mappings}
							linkedItems={pageResponse.linkedItems}
							className="w-full cms-content-formatting max-w-3xl"
							richTextElement={
								page.elements.web_content_rtb__content
							}
						/>
					)}
				</Grid>
				<Grid item xs={12} className="order-2 lg:order-[initial]">
					<div ref={refScrollToAfterFilter} />
					<div className="flex flex-wrap gap-4 lg:gap-8">
						<div className="flex-1 basis-full lg:basis-0 max-w-3xl">
							<SearchBar
								variant="with-icon"
								searchBarPlaceholder="Search for glossary term"
								onSearch={(text) => {
									setQS(
										{
											q: text || null,
											p: null,
										},
										DEFAULT_NUQS_TRANSITION_OPTIONS,
									)
								}}
								value={qs.q}
								initialSearchText={qs.q}
								// to prevent running onSearch on unmount
								disableResetSearchText
								debounceTime={300}
							/>
						</div>
						<label className="flex items-center gap-3 !mr-0">
							<span className="flex-shrink-0 nsw-form__label">
								Results per page:
							</span>
							<select
								className="nsw-form__select max-w-[200px] !mt-0"
								onChange={(e) => {
									const value = parseInt(e.target.value)
									setQS(
										{
											p: null,
											ps:
												value === PAGE_SIZE_OPTIONS[0]
													? null
													: value,
										},
										DEFAULT_NUQS_TRANSITION_OPTIONS,
									)
								}}
								value={qs.ps}
								autoComplete="off"
							>
								{PAGE_SIZE_OPTIONS.map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</select>
						</label>
					</div>
				</Grid>
				<Grid
					className="!pt-6 !pb-2 lg:!pt-8 order-1 lg:order-[initial]"
					item
					xs={12}
					lg={3}
				>
					<NswFilter
						className={clsx(
							'lg:sticky top-0',
							String`[&_.nsw-filters\\_\\_item]:py-6`,
						)}
						css={{
							'& > .nsw-filters__controls': {
								borderBottom: 0,
								paddingTop: 0,
								paddingBottom: 0,
							},
							'& > .nsw-nsw-form__fieldset': {
								padding: 0,
							},
							'& .nsw-form__legend': {
								paddingBottom: 12,
							},
							'.is-preview &': {
								top: 26,
							},
						}}
						totalItems={glossariesResponse?.json?.total_records}
						title=""
						mobileToggleFilterLabel="Show filters"
					>
						<NswFilterList>
							<NswFilterItem>
								<NswFormFieldset title="A-Z">
									<GlossaryFilters
										selectedLetters={selectedLetters}
										lettersCount={
											glossariesResponse?.json?.letters
										}
										onSelect={(letter) => {
											// check selected letters. If it's already selected, remove it
											const newLetters =
												selectedLetters.includes(letter)
													? selectedLetters.filter(
															(l) => l !== letter,
													  )
													: [
															...selectedLetters,
															letter,
													  ]
											setQS(
												{
													l:
														newLetters.join(',') ||
														null,
													p: null,
												},
												DEFAULT_NUQS_TRANSITION_OPTIONS,
											)
										}}
									/>
								</NswFormFieldset>
							</NswFilterItem>
							<NswFilterItem>
								<NswFormFieldset title="Learning areas and syllabuses">
									<TreeMultiSelect
										key={klaOptions.join(',')}
										data={klaOptions}
										onChange={handleSyllabusesFilterChange}
										placeholder="Select syllabuses"
										texts={{
											label: 'Select syllabuses',
										}}
										hideTagsSelected
									/>
								</NswFormFieldset>
							</NswFilterItem>
							<NswFilterItem>
								<FormGroupRadio
									label="Filter by"
									className="tree-picker__checkbox"
									options={termOptions}
									onChange={(e) => {
										const value = e.target.value
										setQS(
											{
												t: value || null,
												p: null,
											},
											DEFAULT_NUQS_TRANSITION_OPTIONS,
										)
									}}
								/>
							</NswFilterItem>
						</NswFilterList>
						<NswFilterCancel onReset={handleReset} />
					</NswFilter>
				</Grid>
				<Grid
					className="order-2 lg:order-[initial]"
					item
					xs={12}
					lg={9}
				>
					<div className="relative lg:border-t border-nsw-grey-04">
						<TagListTreeMultiSelectWrapper
							className={clsx({
								'py-8 border-b border-nsw-grey-04':
									selectedLetters.length > 0 ||
									selectedFilterTagsSyllabus.length > 0 ||
									selectedTermType,
							})}
							showClearButton={
								getArrayLength(selectedLetters) +
									getArrayLength(selectedFilterTagsSyllabus) +
									(selectedTermType ? 1 : 0) >
								1
							}
							onClearClick={handleReset}
						>
							{selectedLetters.length > 0 && (
								<TagListTreeMultiSelectNode
									list={selectedLetters.map((item) => {
										return {
											text: item.toUpperCase(),
											label: item.toUpperCase(),
											value: item,
										}
									})}
									onRemoveClick={(_e, removedNode) => {
										const letters =
											selectedLetters
												.filter(
													(l) =>
														l !== removedNode.value,
												)
												.join(',') || null
										setQS(
											{
												l: letters,
												p: null,
											},
											DEFAULT_NUQS_TRANSITION_OPTIONS,
										)
									}}
								/>
							)}
							{selectedFilterTagsSyllabus.length > 0 && (
								<TagListTreeMultiSelectNode
									list={selectedFilterTagsSyllabus}
									onRemoveClick={handleRemoveTagClick(
										's',
										selectedFilterSyllabus,
										handleRemoveNodeSyllabus,
									)}
								/>
							)}
							{termSelectedTreeNodeProps && (
								<TagListTreeMultiSelectNode
									list={termSelectedTreeNodeProps}
									onRemoveClick={(_e) => {
										setQS(
											{
												t: null,
												p: null,
											},
											DEFAULT_NUQS_TRANSITION_OPTIONS,
										)
									}}
								/>
							)}
						</TagListTreeMultiSelectWrapper>
						{glossariesResponse?.json?.data?.length > 0 ? (
							<>
								<NswResultBar
									className={clsx('flex-wrap gap-3 lg:!pt-6')}
									css={{
										'&&': {
											marginTop: 0,
											alignItems: 'center',
											lineHeight: '1.75rem',
											display: 'flex',
											gap: 12,
										},
										'.nsw-results-bar__info': {
											flex: '1 0 100%',
											display: 'flex',
											justifyContent: 'space-between',
											flexWrap: 'wrap',
											gap: 16,
										},
										'.nsw-results-bar__sorting': {
											display: 'none',
										},
									}}
									page={qs.p}
									pageSize={qs.ps}
									total={
										glossariesResponse.json.total_records
									}
									slotShowingResultsAfter={
										<NswButton
											className="flex items-center gap-2 w-full lg:w-auto justify-center"
											onClick={handleDownload}
											disabled={
												isFetchingGlossaries ||
												!glossariesResponse?.json?.data
													?.length
											}
										>
											<span>Download</span>
											<Icon icon="bxs:download" />
										</NswButton>
									}
								></NswResultBar>
								<AccordionGroup>
									{glossariesResponse.json.data.map((t) => {
										const closed = selectedTerm
											? !(
													selectedTerm.toLowerCase() ===
													t.term.toLowerCase()
											  )
											: true
										return (
											<div
												key={t.term}
												id={`accordion-term-${sanitizeIdAttribute(
													slugify(
														t.term.toLowerCase(),
													),
												)}`}
											>
												<Accordion
													header={t.term}
													body={
														<>
															{t.items.map(
																(
																	definition,
																) => (
																	<div
																		key={
																			definition
																				.system
																				.id
																		}
																		className="glossary-body__definition"
																		data-kontent-item-id={
																			definition
																				.system
																				.id
																		}
																	>
																		<CommonCopyUrlWrapper
																			url={`/resources/glossary/${definition.slug}`}
																			className="mb-4"
																		>
																			<div className="nsw-h4">
																				{
																					definition.title
																				}
																			</div>
																		</CommonCopyUrlWrapper>
																		<RichText
																			richTextElement={normalizeRichtextElementFromAzure(
																				definition.description,
																			)}
																			linkedItems={convertLinkedItemsArrayToRecordFromAzure(
																				definition
																					.description
																					.modular_content,
																			)}
																			data-kontent-element-codename="description"
																			mappings={
																				mappings
																			}
																		/>

																		<GlossaryItemTagList
																			className="mt-6"
																			glossaryItem={
																				definition
																			}
																		/>
																	</div>
																),
															)}
														</>
													}
													closed={closed}
												/>
											</div>
										)
									})}
								</AccordionGroup>
								{glossariesResponse.json.total_pages > 1 && (
									<div className="mt-16">
										<Pagination
											page={qs.p}
											count={
												glossariesResponse.json
													.total_pages
											}
											onChange={(_, page) => {
												setQS(
													{
														p:
															page === 1
																? null
																: page,
													},
													DEFAULT_NUQS_TRANSITION_OPTIONS,
												)
												scrollToTopOfRightCol()
											}}
										/>
									</div>
								)}
							</>
						) : isFetchingGlossaries ? null : (
							<h4 className="text-center mt-20">
								{/* eslint-disable-next-line quotes */}
								{"We didn't find any results. "}
								<button
									type="reset"
									className="underline bold nsw-text--brand-dark"
									onClick={handleReset}
								>
									Clear all filters
								</button>
							</h4>
						)}

						{isFetchingGlossaries && (
							<div className="bg-white/90 absolute inset-0 w-full flex justify-center pt-6">
								<Loading />
							</div>
						)}
					</div>
				</Grid>
			</GridWrapper>
			<GeneratingOverlayCommon
				modalStatus={isDownloading}
				handleCancel={handleCancelDownload}
			></GeneratingOverlayCommon>
			{errorPopupMessage && (
				<CustomModal
					title="Error"
					modalStatus={!!errorPopupMessage}
					handleCancel={() => setErrorPopupMessage('')}
					hideConfirmButton
				>
					<p>{errorPopupMessage}</p>
				</CustomModal>
			)}
		</>
	)
}

export default WpGlossary
