import InPageNav from '@/components/InPageNav'
import RichText from '@/components/RichText'
import UnknownComponent from '@/components/UnknownComponent'
import { AssetsProvider } from '@/components/contexts/AssetsProvider'
import { useKontentHomeConfig } from '@/components/contexts/KontentHomeConfigProvider'
import { GridCol } from '@/components/nsw/grid/GridCol'
import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { SideNav, SideNavItem } from '@/components/nsw/side-nav/SideNav'
import { CommonCopyUrlWrapper } from '@/components/ui/copy-to-clipboard/CommonCopyUrlWrapper'
import { useCleanPathDefault } from '@/hooks/useCleanPathDefault'
import type { WebLandingPage } from '@/kontent/content-types/web_landing_page'
import type { AssetWithRawElements, CommonPageProps } from '@/types'
import { isYes } from '@/utils'
import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { GlobalAlertAce } from './wp_ace_landing'

const scrollMarginIfTheresGlobalAlert = (isPreview) => css`
	&&& h1[id],
	&&& h2[id],
	&&& h3[id],
	&&& h4[id],
	&&& h5[id],
	&&& h6[id] {
		scroll-margin-top: calc(
			var(--global-alert-ace-height) ${isPreview ? '+ 26px' : ''}
		);
	}
`

function PageWebLanding(
	props: CommonPageProps<WebLandingPage> & {
		sideNavItems: SideNavItem[]
		headerSideNavItem: SideNavItem
		assets: AssetWithRawElements[]
	},
) {
	const { mappings } = useKontentHomeConfig()
	const { pageResponse, config } = props.data
	const page = pageResponse.item
	const currentUrl = useCleanPathDefault()

	if (!page) {
		return (
			<UnknownComponent>
				Page {page.system.codename} does not have any content!
			</UnknownComponent>
		)
	}

	const isShowInPageNavigation = isYes(page.elements.show_in_page_navigation)

	const hasAceGlobalAlert =
		/^ace_/gi.test(page.system.codename) &&
		config.item.elements.global_alert_ace.value.length > 0

	return (
		<>
			{
				// if the page codename starts with ace_, show global alert ace
				hasAceGlobalAlert && (
					<GlobalAlertAce config={config} mappings={mappings} />
				)
			}
			<GridWrapper className="mt-0">
				<GridCol>
					{page.elements.title.value && (
						<CommonCopyUrlWrapper url={currentUrl}>
							<h1
								data-kontent-item-id={page.system.id}
								data-kontent-element-codename="title"
							>
								{page.elements.title.value}
							</h1>
						</CommonCopyUrlWrapper>
					)}
				</GridCol>
				<GridCol lg={3}>
					<SideNav
						header={props.headerSideNavItem}
						items={props.sideNavItems}
						withToggle
					/>
				</GridCol>
				<GridCol lg={9}>
					<div
						className="NonFullWidthWrapper"
						style={
							{
								'--viewport-width': '100%',
								'--nsw-container': '100%',
							} as CSSProperties
						}
					>
						<AssetsProvider assets={props.assets}>
							<RichText
								className="w-full cms-content-formatting "
								mappings={props.mappings}
								linkedItems={pageResponse.linkedItems}
								richTextElement={
									page.elements.web_content_rtb__content
								}
								data-kontent-item-id={page.system.id}
								data-kontent-element-codename="web_content_rtb__content"
								css={css(
									hasAceGlobalAlert &&
										scrollMarginIfTheresGlobalAlert(
											props.preview,
										),
									{
										'.module:first-child': {
											marginTop: '-2rem',
										},
									},
								)}
								renderFnBefore={(nodes) => {
									if (!isShowInPageNavigation) return
									return (
										<InPageNav
											className="!mt-0"
											richTextElements={
												nodes as JSX.Element[]
											}
										/>
									)
								}}
							/>
						</AssetsProvider>
					</div>
				</GridCol>
			</GridWrapper>
		</>
	)
}

export default PageWebLanding
