import { CustomSyllabusPage } from '@/components/CustomSyllabusPage'
import { Loading } from '@/components/Loading'
import { SyllabusTabsToElement } from '@/constants'
import { fetchItems } from '@/fetchers'
import { Syllabus } from '@/kontent/content-types'
import { WpStage as WpStageModel } from '@/kontent/content-types/wp_stage'
import { contentTypes } from '@/kontent/project/contentTypes'
import {
	CommonPageProps,
	CustomSyllabusTab,
	KontentCurriculumCommonResultData,
	TaxoStageWithLifeSkill,
} from '@/types'
import {
	filterPreviewableSyllabusesOnly,
	getTaxoCodenames,
	isAllowPreviewExternalSyllabus,
} from '@/utils'
import { useQuery } from '@tanstack/react-query'

export default function WpStage(
	props: CommonPageProps<
		WpStageModel,
		KontentCurriculumCommonResultData<WpStageModel>
	>,
) {
	const { preview, data } = props

	const { data: glossariesAssetsResponse, isFetched: isFetchedGlossaries } =
		useQuery(
			[props.data.pageResponse.item.system.codename, preview],
			async () => {
				const syllabuses = await fetchItems<Syllabus>(
					'syllabus',
					[
						contentTypes.syllabus.elements.syllabus.codename,
						contentTypes.syllabus.elements.doredirect.codename,
						contentTypes.syllabus.elements.allowpreview.codename,
					].join(','),
				)

				// published syllabus
				const syllabusesItems = isAllowPreviewExternalSyllabus()
					? syllabuses.items.filter(filterPreviewableSyllabusesOnly)
					: syllabuses.items

				return {
					glossaries: null,
				}
			},
		)

	let initialSelectedStages: TaxoStageWithLifeSkill[] = getTaxoCodenames(
		props.data.pageResponse.item.elements.stages__stages,
	)
	if (initialSelectedStages.includes('stage_6')) {
		initialSelectedStages = [...initialSelectedStages, 'life_skills']
	}

	if (!isFetchedGlossaries) {
		return (
			<div className="nsw-container pt-8">
				<Loading />
			</div>
		)
	}

	return (
		<>
			<CustomSyllabusPage
				{...props}
				data={{
					...props.data,
				}}
				initialSelectedStages={initialSelectedStages}
				initialSelectedTabs={
					Object.keys(SyllabusTabsToElement) as CustomSyllabusTab[]
				}
			/>
		</>
	)
}
