import { Glossary } from '@/kontent/content-types'
import { IPropWithClassName } from '@/types'
import { FetchGlossariesItem } from '@/types/fetchGlossariesFromAzure'
import { isGlossary } from '@/utils/type_predicates'
import { TagList } from 'nsw-ds-react'

export type GlossaryItemTagListProps = IPropWithClassName & {
	glossaryItem: FetchGlossariesItem | Glossary
	hideSyllabusTags?: boolean
}

const fnMapObjNameToText = (obj: { name: string }) => {
	return {
		text: obj.name,
	}
}

export function GlossaryItemTagList({
	className,
	glossaryItem,
	hideSyllabusTags = false,
}: GlossaryItemTagListProps) {
	const syllabusTaxos = isGlossary(glossaryItem)
		? glossaryItem.elements.syllabus.value
		: glossaryItem.syllabus
	const resourceTypeTaxos = isGlossary(glossaryItem)
		? glossaryItem.elements.type.value
		: glossaryItem.type
	const aceCategoryTaxos = isGlossary(glossaryItem)
		? glossaryItem.elements.ace_category.value
		: glossaryItem.ace_category

	const syllabusTags = !hideSyllabusTags
		? syllabusTaxos.map(fnMapObjNameToText)
		: []
	const resourceTypeTags = resourceTypeTaxos
		.filter((type) => type.codename !== 'ace')
		.map(fnMapObjNameToText)
	const aceCategoryTags = aceCategoryTaxos.map(fnMapObjNameToText)

	const tags = [...syllabusTags, ...resourceTypeTags, ...aceCategoryTags]

	if (!tags.length) return null

	return <TagList className={className} tags={tags} />
}
